<template>
    <appAddResulsTemp :is-new="false" />
  </template>
  
  <script>
  import appAddResulsTemp from "./addResultsTemp";
  
  export default {
    components: { appAddResulsTemp },
  };
  </script>
  
  <style></style>
  
<template>
  
<div>
  <v-container class="py-10">
     
          <h1 >{{ isNew ? $t("Add Template Results") : $t('edit') }}</h1>
    <v-spacer></v-spacer>
    <v-col md="12"></v-col>
          <v-row>
            <v-col md="4">
              <v-text-field
                outlined
                dense
                :label="$t('name')"
                v-model="structure.name"
              ></v-text-field>
            </v-col>
            <v-col md="4">
                <v-autocomplete v-model="structure.educational_year_id" outlined dense :items="educationalYears"
                    :label="$t('educational year')" item-text="name" item-value="id" @input="fetchDataTerms"></v-autocomplete>
            </v-col>
            <v-col md="4">
                <v-autocomplete v-model="structure.grade_type_id" outlined dense :items="gradeTypes"
                    :label="$t('class type')" item-text="name" item-value="id" @input="fetchDataSubjects"></v-autocomplete>
            </v-col>
          
           
          </v-row>
          <v-container  id="buffet-table" fluid tag="section">
            <v-col md="10">

              <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading">
                  <tr>
                    <th >{{ $t("subject") }}</th>
                  
                    <th>{{ $t("sorting") }}</th>
                    <th>{{ $t("Check") }}</th>
                  </tr>
                </thead>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                  <tr v-for="(el,ix) in structure.subjects" :key="ix">
                    <td>{{ el.name }}</td>
                    <td>
                      <v-text-field
                        full-width
                        dense
                        hide-details
                        
                        type="number"
                        flat
                        color="#757575"
                        v-model="el.order"
                      ></v-text-field>
                    </td>
                    <td>
                        <v-checkbox
                          class="mx-4 mb-4"
                          v-model="el.check"
                          hide-details
                      ></v-checkbox>           
                    </td>
                  </tr>
                </v-fade-transition>
              </v-simple-table>
            </v-col>
     </v-container>
     <v-row></v-row>
     <v-container  id="buffet-table" fluid tag="section">
              <v-col md="10">
                <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading">
                  <tr>
                    <th >{{ $t("term") }}</th>
                    <th >{{ $t("Percent") }}</th>
                    <th>{{ $t("sorting") }}</th>
                    <th>{{ $t("Check") }}</th>
                  </tr>
                </thead>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                  <tr v-for="(el,ix) in structure.terms" :key="ix">
                    <td>{{ el.name }}</td>
                    <td>
                      <v-row><v-col>
                        <v-text-field
                          full-width
                          dense
                          hide-details
                          
                          type="number"
                          flat
                          color="#757575"
                          v-model="el.percent"
                        ></v-text-field> </v-col><v-col><h3 class="mt-2" >%</h3></v-col>
                      </v-row>  
                    </td>
                    <td>
                      <v-text-field
                        full-width
                        dense
                        hide-details
                        
                        type="number"
                        flat
                        color="#757575"
                        v-model="el.order"
                      ></v-text-field>
                    </td>
                    <td>
                        <v-checkbox
                          class="mx-4 mb-4"
                          v-model="el.check"
                          hide-details
                      ></v-checkbox>           
                    </td>
                  </tr>
                </v-fade-transition>
              </v-simple-table>
              </v-col>
     </v-container>
     
         <v-col class="justify-end">
          <v-btn
            color="green"
            depressed
            class="white--text"
            @click="submit"
            :loading="btnLoading"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="$router.go(-1)">
                    {{ $t('cancel') }}
                </v-btn>
 

         </v-col>
        </v-container>
         
</div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
    
      
      structure: {
        name: null,
        grade_type_id: null,
        educational_year_id: null,
        subjects: [],
        terms: [],
      },
    };
  },
  props: {
    
    isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
  },
  computed: {
    ...mapGetters({
      educationalYears: "educationalYear/getEducationalYears",
      gradeTypes: "gradeType/getGradeTypes",
      terms: "terms/getTerms",
      subjects: "subjects/getSubjects",
    }),
    
  },
  methods: {
    
    ...mapActions({
      fetchEduYears: "educationalYear/fetchAllEduYears",
      fetchGradeTypes: "gradeType/fetchAllGradeTypes",
      fetchTerms: "terms/fetchTerms",
      fetchSubjects: "subjects/fetchSubjects",
    }),
    fetchDataTerms(){
        this.fetchTerms({
        searchEducationalYearId: this.structure.educational_year_id,
      });
      this.structure.terms = this.terms.map((el)=>({
        ...el,
        percent: null,
        check: true,
          order: null,
      }));
    },
    fetchDataSubjects(){
        this.fetchSubjects({
            grade_type_id: this.structure.grade_type_id,
          });
          this.structure.subjects = this.subjects.map((el)=>({
            ...el,
            check: true,
            order: null,
          }));
    },
    async submit() {
      try {
       
        this.btnLoading = true;
        let tempSub = this.structure.subjects;
        let tempTer = this.structure.terms;
        this.structure.subjects = [];
        this.structure.terms = [];
        for(let i=0;i<tempSub.length;i++){
          if(tempSub[i].check){
            this.structure.subjects.push({
              subject_id: tempSub[i].id,
              order: tempSub[i].order,
            });
          }
        }
        for(let i=0;i<tempTer.length;i++){
          if(tempTer[i].check){
            this.structure.terms.push({
              term_id: tempTer[i].id,
              order: tempTer[i].order,
              percent: tempTer[i].percent,
            });
          }
        }
     
        console.log(this.structure.subjects);
        console.log("object");
        console.log(this.structure.terms);

        

        if(this.isNew) {
        const res = await axios.post("/final-grade", this.structure);
          if(res.status==200)
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else{
          const res = await axios.put(`/final-grade/${this.$route.params.id}`, this.structure);
          if(res.status==200)
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        this.$router.go(-1);
   
      } catch (err) {
      } finally {
        this.btnLoading = false;
      
      }
    },
    
  },
  async  created() {
    this.fetchEduYears();
    this.fetchGradeTypes();
    this.structure.subjects.push({
      name: null,
      order: null,
      check: null,
    });
   
    this.structure.terms.push({
      name: null,
      percent: null,
      order: null,
      check: null,
    });
    if(!this.isNew){

    const res = await axios.get(`/final-grade/${this.$route.params.id}`);
    console.log(res.data.data);
    let struct = res.data.data;
    this.structure.name = struct.name;
    this.structure.educational_year_id = struct.educational_year.id;
    this.structure.grade_type_id = struct.grade_type.id;
    this.structure.subjects = struct.subjects.map((el)=>({
      ...el,
      check: true,
    }));
    this.structure.terms = struct.terms.map((el)=>({
      ...el,
      check: true,
    }));
    } 

  },
};
</script>

<style>

.tableId {
 padding: 0px 10px;
 border-collapse: collapse;
 border-radius: 10%;
 text-align: center;
 
}
#buffet-table th {
  color: #909399;
  font-size: 1rem;
  text-align: center;
}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
}
</style>